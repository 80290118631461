import { createStore } from "vuex";

export default createStore({
  state: {
    questions: [
      {
        id: 1,
        question: "What is the most common first word for a baby?",
        answers: [
          { id: 1, answer: "Mama", score: 35 },
          { id: 2, answer: "Dada", score: 30 },
          { id: 3, answer: "No", score: 15 },
          { id: 4, answer: "Bye", score: 10 },
          { id: 5, answer: "Ball", score: 5 },
          { id: 6, answer: "Hi", score: 5 },
        ],
      },
      {
        id: 2,
        question: "Name something babies use every day.",
        answers: [
          { id: 7, answer: "Diapers", score: 40 },
          { id: 8, answer: "Pacifier", score: 20 },
          { id: 9, answer: "Bottle", score: 15 },
          { id: 10, answer: "Baby wipes", score: 10 },
          { id: 11, answer: "Blanket", score: 5 },
          { id: 12, answer: "High chair", score: 5 },
        ],
      },
      {
        id: 3,
        question: "Name a popular bedtime story.",
        answers: [
          { id: 13, answer: "Goodnight Moon", score: 30 },
          { id: 14, answer: "The Very Hungry Caterpillar", score: 25 },
          { id: 15, answer: "Where the Wild Things Are", score: 15 },
          { id: 16, answer: "Cat in the Hat", score: 10 },
          { id: 17, answer: "Goldilocks and the three bears", score: 5 },
          { id: 18, answer: "Jack and the Beanstalk", score: 5 },
        ],
      },
      {
        id: 4,
        question: "Name something you find in a baby's nursery.",
        answers: [
          { id: 19, answer: "Crib", score: 35 },
          { id: 20, answer: "Changing table", score: 20 },
          { id: 21, answer: "Diaper pail", score: 15 },
          { id: 22, answer: "Rocking chair", score: 10 },
          { id: 23, answer: "Mobile", score: 5 },
          { id: 24, answer: "Stuffed animals", score: 5 },
        ],
      },
      {
        id: 5,
        question: "Name a popular lullaby.",
        answers: [
          { id: 25, answer: "Rock-a-Bye Baby", score: 30 },
          { id: 26, answer: "Twinkle, Twinkle, Little Star", score: 25 },
          { id: 27, answer: "Hush, Little Baby", score: 15 },
          { id: 28, answer: "Brahms' Lullaby/Cradle Song", score: 10 },
          { id: 29, answer: "You Are My Sunshine", score: 5 },
          { id: 30, answer: "Mary Had a Little Lamb", score: 5 },
        ],
      },
      {
        id: 6,
        question: "Name a common baby toy.",
        answers: [
          { id: 26, answer: "Rattle", score: 35 },
          { id: 27, answer: "Stuffed animal", score: 25 },
          { id: 28, answer: "Teething toy", score: 20 },
          { id: 29, answer: "Building blocks", score: 10 },
          { id: 30, answer: "Play mat", score: 5 },
        ],
      },
      {
        id: 7,
        question: "Name a popular baby sleep aid.",
        answers: [
          { id: 31, answer: "Lullaby music/white noise", score: 30 },
          { id: 32, answer: "Swaddle", score: 25 },
          { id: 33, answer: "Pacifier", score: 20 },
          { id: 34, answer: "Rocking chair", score: 15 },
          { id: 35, answer: "Mobile", score: 10 },
        ],
      },
      {
        id: 8,
        question: "What is a popular first food for babies?",
        answers: [
          { id: 36, answer: "Cereal/Oatmeal/Rice", score: 35 },
          { id: 37, answer: "Pureed Vegtables/Fruits", score: 30 },
          { id: 38, answer: "Egg", score: 20 },
          { id: 39, answer: "Beans/Lentils", score: 10 },
          { id: 40, answer: "Yogurt/Cheese", score: 5 },
        ],
      },
      {
        id: 9,
        question: "Name a baby clothing item parents can't have enough of.",
        answers: [
          { id: 41, answer: "Onesies", score: 40 },
          { id: 42, answer: "Socks", score: 25 },
          { id: 43, answer: "Bibs", score: 20 },
          { id: 44, answer: "Hats", score: 10 },
          { id: 45, answer: "Sleepers", score: 5 },
        ],
      },
      {
        id: 10,
        question: "What is a common piece of advice for new parents?",
        answers: [
          { id: 46, answer: "Sleep when the baby sleeps", score: 30 },
          { id: 47, answer: "Trust your instincts", score: 25 },
          { id: 48, answer: "Ask for help", score: 20 },
          { id: 49, answer: "Take lots of pictures", score: 15 },
          { id: 50, answer: "Enjoy every moment", score: 10 },
        ],
      },
    ],
    currentQuestionIndex: 0,
    teams: [
      {
        id: 1,
        name: "Alpha",
        score: 0,
        answers: [],
      },
      {
        id: 2,
        name: "Beta",
        score: 0,
        answers: [],
      },
    ],
    currentTeamIndex: 0,
    gameStatus: "playing",
    showWinnerPopup: false,
  },
  getters: {
    currentQuestion: (state) => state.questions[state.currentQuestionIndex],
    teams: (state) => state.teams,
    showWinnerPopup: (state) => state.showWinnerPopup,
    currentTeam: (state) => state.teams[state.currentTeamIndex], // Use the currentTeamIndex
    otherTeam: (state) => state.teams[1 - state.currentTeamIndex], // Get the other team
    isLastQuestion: (state) =>
      state.currentQuestionIndex === state.questions.length - 1,
    winnerTeam: (state) => {
      return state.teams.reduce(
        (highest, team) => (team.score > highest.score ? team : highest),
        { score: -1 }
      );
    },
  },
  mutations: {
    nextOrEndGame(state) {
      if (state.currentQuestionIndex < state.questions.length - 1) {
        console.log("Incrementing question index...");
        state.currentQuestionIndex++;
        state.currentTeamIndex =
          (state.currentTeamIndex + 1) % state.teams.length;
      } else {
        console.log("End game, no more questions...");
        state.gameStatus = "ended";
      }
    },
    endGame(state) {
      state.gameStatus = "ended";
    },

    updateAnswers(state, answerId) {
      // Get the answer object using the answerId
      const answer = state.questions[state.currentQuestionIndex].answers.find(
        (a) => a.id === answerId
      );

      if (answer) {
        // Add score to the current team and reveal the answer
        state.teams[state.currentTeamIndex].score += answer.score;
        state.teams[state.currentTeamIndex].answers.push(answer.id);
      }
    },
    switchTeams(state) {
      state.currentTeam = state.currentTeam === 1 ? 2 : 1;
    },
    resetGame(state) {
      state.currentQuestionIndex = 0;
      state.currentTeamIndex = 0;
      state.gameStatus = "playing";
      state.teams.forEach((team) => {
        team.score = 0;
      });
    },
    setShowWinnerPopup(state, value) {
      state.showWinnerPopup = value;
    },
  },
  actions: {
    answerQuestion({ commit }, answerId) {
      commit("updateAnswers", answerId); // Call updateAnswers, not updateAnswer
    },
  },
});
