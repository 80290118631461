<template>
  <div class="mt-6">
    <h2 class="text-2xl font-bold text-white mb-2">Scoreboard:</h2>
    <div 
      class="text-white text-xl mb-2"
      v-for="(team) in teams"
      :key="team.id"
    >
      {{ team.name }}: {{ team.score }} Points
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ScoringComponent",
  computed: {
    ...mapGetters(["teams"]),
  },
};
</script>

<style>
</style>
