<template>
  <div class="container mx-auto max-w-7xl">
    <div class="grid grid-cols-12 gap-4">
      <div class="col-span-12">
        <question-component />
      </div>
      <div class="col-span-12">
        <answers-component />
      </div>
    </div>
  </div>
  <div class="container mx-auto py-8">
    <div class="grid grid-cols-1 gap-4">
      <scoring-component />
    </div>
  </div>
  
</template>

<script>
import QuestionComponent from "./components/QuestionComponent.vue";
import AnswersComponent from "./components/AnswersComponent.vue";
import ScoringComponent from './components/ScoringComponent.vue';
export default {
  name: "App",
  components: {
    QuestionComponent,
    AnswersComponent,
    ScoringComponent,
  },
};
</script>

<style>
#app {
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
body::before {
  content: "";
  background-image: url("~@/assets/bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  z-index: -1;
}

body::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
}
</style>
