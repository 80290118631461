<template>
  <div
    v-if="showWinnerPopup"
    class="relative z-10"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
    ></div>

    <div class="fixed inset-0 z-10 overflow-y-auto">
      <div
        class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
      >
        <div
          class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6"
        >
          <div>
            <div class="mt-3 text-center sm:mt-5">
              <h3
                class="text-base font-semibold leading-6 text-gray-900"
                id="modal-title"
              >
                WINNER!
              </h3>
              <div class="mt-2">
                <p class="text-sm text-gray-500">
                  Team {{ winnerTeam.name }} won with
                  {{ winnerTeam.score }} points!
                </p>
              </div>
            </div>
          </div>
          <div class="mt-5 sm:mt-6">
            <button
              type="button"
              @click="restartGame"
              class="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Reset Game
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  props: ["showPopup"],
  computed: {
    ...mapGetters(["winnerTeam", "showWinnerPopup"]),
  },
  methods: {
    ...mapMutations(["resetGame", "setShowWinnerPopup"]),
    restartGame() {
      this.resetGame();
      this.setShowWinnerPopup(false);
    },
  },
};
</script>
  