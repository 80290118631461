<template>
  <div class="grid grid-cols-12 gap-4">
    <div
      v-for="(answer, index) in currentQuestion.answers"
      :key="answer.id"
      class="col-span-6"
    >
      <button
        class="w-full bg-gradient-to-b from-blue-500 to-blue-900 shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col items-center justify-center my-2 h-32"
        @click="answerQuestionAndUpdate(answer.id)"
      >
        <div class="text-center">
          <div v-if="isAccepted(answer.id)">
            <span class="text-xl text-white"
              >{{ answer.answer }} ({{ answer.score }})</span
            >
          </div>
          <div v-else>
            <span
              class="inline-flex items-center justify-center h-12 w-12 rounded-full bg-blue-900 shadow-lg text-xl text-white"
              >{{ index + 1 }}</span
            >
          </div>
        </div>
      </button>
    </div>
    <div class="col-span-12">
      <button
        class="bg-yellow-600 px-6 rounded text-white py-3 flex-col items-center justify-center mx-3 shadow-lg text-xl"
        @click="nextOrFinish()"
      >
        {{ isLastQuestion ? "Finish" : "Next" }}
      </button>
      <WinnerPopup
        v-if="showWinnerPopup"
        :winner="winnerTeam"
        :teams="teams"
        @close-popup="hideWinnerPopup"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import WinnerPopup from "./WinnerPopup.vue";
export default {
  components: {
    WinnerPopup,
  },
  created() {
    console.log("Initial currentQuestionIndex", this.currentQuestionIndex);
    console.log("Initial questions length", this.questions.length);
    console.log(this.showWinnerPopup);
  },
  computed: {
    ...mapGetters([
      "currentQuestion",
      "teams",
      "currentTeam",
      "winnerTeam",
      "isLastQuestion",
      "showWinnerPopup"
    ]),
  },

  watch: {
    currentQuestionIndex(newVal, oldVal) {
      console.log("currentQuestionIndex changed from", oldVal, "to", newVal);
    },
    questions(newVal, oldVal) {
      console.log(
        "Questions changed from length",
        oldVal.length,
        "to",
        newVal.length
      );
    },
  },
  methods: {
    ...mapActions(["answerQuestion"]),
    ...mapMutations(["nextOrEndGame", "endGame", "resetGame", "setShowWinnerPopup"]),
    ...mapGetters(["questions", "currentQuestionIndex", "showWinnerPopup"]),
    isAccepted(id) {
      return this.currentTeam ? this.currentTeam.answers.includes(id) : false;
    },
    hideWinnerPopup() {
      this.showWinnerPopup = false;
    },

    nextOrFinish() {
      if (this.isLastQuestion) {
        console.log("This is the last question. Ending the game...");
        this.endGame();
        this.setShowWinnerPopup(true);
        console.log(this.showWinnerPopup);
      } else {
        console.log("There are more questions. Moving to the next one...");
        this.nextOrEndGame();
      }
    },
    answerQuestionAndUpdate(answerId) {
      if (!this.isAccepted(answerId)) {
        console.log("Answering question with id", answerId);
        this.answerQuestion(answerId);
      } else {
        console.log("Question already answered.");
      }
    },
  },
};
</script>