<template>
  <div class="mb-4">
    <h1 class="text-4xl font-bold text-white">
      {{ currentQuestion.id }}. {{ currentQuestion.question }}
    </h1>
    <p class="py-3 text-xl font-bold text-white">{{ currentTeam.name }} Team Playing</p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    name: "QuestionComponent",
    computed: {
        ...mapGetters(["currentQuestion", "currentTeam"]),
    },
};
</script>
